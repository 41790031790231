import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DashboardComponent} from "./routes/dashboard/dashboard.component";
import {RoutePaths} from "./routes/RoutePaths";
import {ApiPermissions} from "#psygnal-model/shared/ApiPermissions";
import {RouteAuthorizationService,
  LoginContinueRouteComponent,
  LoginSplashRouteComponent,
  UnauthorizedRouteComponent} from "portal-lib";
import {MyFacilitiesComponent} from "./routes/my-facilities/my-facilities.component";


const routes: Routes = [
  // Root
  {
    path: RoutePaths.Dashboard,
    component: DashboardComponent,
    canActivate: [RouteAuthorizationService],
    data: {
      routeAuthorization: {
        requireAll: false,
        permissions: [
          ApiPermissions.AccessProviderPortal
        ]
      }
    }
  },
  // Auth
  {
    path: RoutePaths.Login,
    component: LoginSplashRouteComponent,
    canActivate: [RouteAuthorizationService],
    // allow anonymous by not including routeAuthorization data
  },
  {
    path: RoutePaths.LoginContinue,
    component: LoginContinueRouteComponent,
    canActivate: [RouteAuthorizationService]
    // allow anonymous by not including routeAuthorization data
  },
  {
    path: RoutePaths.Unauthorized,
    component: UnauthorizedRouteComponent,
    canActivate: [RouteAuthorizationService],
    data: {
      routeAuthorization: {
        requireAll: false,
        permissions: [] // require authentication only
      }
    }
  },
  {
    path: RoutePaths.MyFacilities,
    component: MyFacilitiesComponent,
    canActivate: [RouteAuthorizationService],
    data: {
      routeAuthorization: {
        requireAll: false,
        permissions: [
          ApiPermissions.AccessProviderPortal
        ]
      }
    }
  },
  {
    path: RoutePaths.Root,
    redirectTo: RoutePaths.MyFacilities,
    pathMatch: "full"
  },
  {
    path: "**",
    redirectTo: RoutePaths.MyFacilities,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
