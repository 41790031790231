<div class="container-fluid ms-auto me-auto" style="width: 100ch;">
  <ul class="breadcrumb">
    <li class="breadcrumb-item active">My Facilities</li>
    <li class="breadcrumb-item" *ngIf="loading">
      <portals-loading-icon></portals-loading-icon>
    </li>
  </ul>
  <form #searchForm="ngForm">
    <fieldset [disabled]="loading">
      <div class="container-fluid mb-3">
        <div class="input-group flex-nowrap">
          <button class="btn btn-secondary dropdown-toggle"
                  ngbTooltip="Filter Results"
                  (click)="$event.stopPropagation(); searchDrop.open();">
            <i class="fas fa-sliders-h"></i>
          </button>
          <div ngbDropdown #searchDrop="ngbDropdown" [autoClose]="true"
               placement="bottom-left">
            <div ngbDropdownMenu class="p-2">
              <div class="clearfix">
                <button type="button" class="btn btn-sm btn-outline-info float-start"
                        (click)="clearForm(clearBtn); searchDrop.close();"
                        #clearBtn>
                  <i class="fas fa-eraser"></i>
                </button>
                <button type="button" class="btn-close float-end" #clearBtn
                        (click)="searchDrop.close()">
                </button>
              </div>
              <h6>Sort</h6>
              <div>
                <label class="small form-label m-0" for="sortField">By Field</label>
                <select name="sortField"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.sortField"
                        id="sortField">
                  <option value="name" selected>Name</option>
                </select>
              </div>
              <div>
                <label class="small form-label m-0" for="sortOrder">Direction</label>
                <select name="sortOrder"
                        class="form-select"
                        (change)="updateSearch()"
                        [(ngModel)]="searchData.sortOrder"
                        id="sortOrder">
                  <option value="asc" selected>Ascending</option>
                  <option value="desc">Descending</option>
                </select>
              </div>
            </div>
          </div>
          <input type="search" class="form-control" placeholder="Search my facilities"
                 #searchControl
                 (keydown)="updateSearch()"
                 (search)="updateSearch()"
                 [disabled]="loading"
                 [(ngModel)]="searchData.searchValue"
                 name="search">
          <button class="btn btn-secondary z-0"
                  (click)="updateSearch()"
                  [disabled]="loading">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </fieldset>
  </form>
  <div *ngIf="!loading">
    <div class="text-center" *ngIf="facilities && facilities.length === 0">
      <em>You have not been associated with any facilities yet.</em>
    </div>
  </div>
  <div>
    <div class="mb-3 text-lg"
         *ngFor="let facility of facilities | sort:searchData.sortField:searchData.sortOrder | filterFunc:searchFilter:searchUpdated">
      <div class="border-bottom p-2 pb-1">
        <span [innerHTML]="facility.name | markResult:searchRegexs"></span>
        <div class="float-end text-sm d-flex flex-wrap">
          <div class="me-2"><small><small><small><strong>state:</strong></small></small></small></div>
          <div class="me-5" style="margin-top: .1em; width: 1em">{{ facility.state }}</div>
          <div class="me-2"><small><small><small><strong>timezone:</strong></small></small></small></div>
          <div style="margin-top: .1em; width: 4em">{{ MapTimezoneCodeToName(facility.timezone) }}</div>
        </div>
      </div>
      <div *ngIf="facility.zoom_links && facility.zoom_links.length > 0; else elseBlock" class="ms-3 mb-1">
        <em>Facility Zoom Links</em>
      </div>
      <ng-template #elseBlock>
        <div class="ms-3">
          <em>This Facility Does Not Have Zoom Links Set Up Yet</em>
        </div>
      </ng-template>
      <div class="d-flex flex-wrap ms-5">
        <div *ngFor="let zoom of facility.zoom_links" class="m-2">
          <a [href]="zoom.url"
             target="_blank"
             class="btn btn-primary">
            <i class="fas fa-fw fa-video text-white"></i>
            {{ zoom.name }}
          </a>
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="d-flex flex-wrap">-->
  <!--    <div class="m-3 border border-primary border-2em p-2"-->
  <!--         style="width: 20vw;"-->
  <!--         *ngFor="let facility of facilities | sort:searchData.sortField:searchData.sortOrder | filterFunc:searchFilter:searchUpdated">-->
  <!--      <div class="fw-bold text-truncate"-->
  <!--           [ngbTooltip]="facility.name">-->
  <!--        <span [innerHTML]="facility.name | markResult:searchRegexs"></span>-->
  <!--      </div>-->
  <!--      <div class="ms-3 small row">-->
  <!--        <div class="col">-->
  <!--          <portals-timezone-icon class="text-primary"></portals-timezone-icon>-->
  <!--          <span class="ms-1 d-inline-block align-middle">-->
  <!--            {{MapTimezoneCodeToName(facility.timezone)}}-->
  <!--          </span>-->
  <!--        </div>-->
  <!--        <div class="col pt-1">-->
  <!--          <i class="fas fa-fw fa-map-marker-alt text-primary"></i>-->
  <!--          <span class="ms-1 d-inline-block align-middle">-->
  <!--            {{facility.state}}-->
  <!--          </span>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div *ngIf="facility.zoom_links?.length > 0"><em>Telehealth</em></div>-->
  <!--      <div class="small ms-3 me-1" *ngIf="facility.zoom_links?.length > 0">-->
  <!--        <a [href]="zoom.url"-->
  <!--           target="_blank"-->
  <!--           class="btn btn-primary"-->
  <!--           *ngFor="let zoom of facility.zoom_links">-->
  <!--          Start Zoom-->
  <!--          <div *ngIf="facility.zoom_links?.length > 1">({{zoom.name}})</div>-->
  <!--        </a>-->
  <!--      </div>-->
  <!--      &lt;!&ndash;      <div *ngIf="facility.appointment_schedule_contacts?.length > 0"><em>Contacts</em></div>&ndash;&gt;-->
  <!--      &lt;!&ndash;      <div *ngIf="facility.appointment_schedule_contacts?.length > 0" class="d-flex flex-wrap"&ndash;&gt;-->
  <!--      &lt;!&ndash;           style="max-height: 8em; overflow-y: scroll; overflow-x: hidden">&ndash;&gt;-->
  <!--      &lt;!&ndash;        <div class="m-1 border border-info p-2 small"&ndash;&gt;-->
  <!--      &lt;!&ndash;             style="border-radius: 1.2em; width: 8vw;"&ndash;&gt;-->
  <!--      &lt;!&ndash;             *ngFor="let contact of facility.appointment_schedule_contacts | sort:'name'">&ndash;&gt;-->
  <!--      &lt;!&ndash;          <div class="text-truncate">&ndash;&gt;-->
  <!--      &lt;!&ndash;            <strong [ngbTooltip]="contact.name"&ndash;&gt;-->
  <!--      &lt;!&ndash;                    style="font-weight: 600">{{contact.name}}</strong>&ndash;&gt;-->
  <!--      &lt;!&ndash;          </div>&ndash;&gt;-->
  <!--      &lt;!&ndash;          <div class="ms-1">&ndash;&gt;-->
  <!--      &lt;!&ndash;            <div>&ndash;&gt;-->
  <!--      &lt;!&ndash;              <em>role:</em>&ndash;&gt;-->
  <!--      &lt;!&ndash;              {{contact.role}}&ndash;&gt;-->
  <!--      &lt;!&ndash;            </div>&ndash;&gt;-->
  <!--      &lt;!&ndash;            <div>&ndash;&gt;-->
  <!--      &lt;!&ndash;              <em>phone:</em>&ndash;&gt;-->
  <!--      &lt;!&ndash;              {{contact.phone}}&ndash;&gt;-->
  <!--      &lt;!&ndash;            </div>&ndash;&gt;-->
  <!--      &lt;!&ndash;          </div>&ndash;&gt;-->
  <!--      &lt;!&ndash;          <div class="text-center">&ndash;&gt;-->
  <!--      &lt;!&ndash;            <em><a [href]="'mailto://' + contact.email" target="_blank">email</a></em>&ndash;&gt;-->
  <!--      &lt;!&ndash;          </div>&ndash;&gt;-->
  <!--      &lt;!&ndash;        </div>&ndash;&gt;-->
  <!--      &lt;!&ndash;      </div>&ndash;&gt;-->
  <!--    </div>-->
  <!--  </div>-->
</div>

