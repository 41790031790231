import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {lastValueFrom} from 'rxjs';
import {ArrayResponse} from "#psygnal-model/shared/ArrayResponse";
import {ProviderFacility} from "#psygnal-model/routes/my/provider/ProviderFacility";
import {CmsItem} from "#psygnal-model/routes/cms/CmsItem";


//region Resource Routes
const MyFacilities = "/my/provider/facilities";
const CmsPath = "/cms?all_versions=true";

//endregion

function url(path: string): string {
  return `${environment.portal.apiUrl}${path}`;
}

@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  constructor(private http: HttpClient) {
  }

  async getMyFacilities() : Promise<ArrayResponse<ProviderFacility>> {
    return lastValueFrom(this.http.get<ArrayResponse<ProviderFacility>>(url(MyFacilities)));
  }

  async getCmsData() : Promise<ArrayResponse<CmsItem>> {
    return lastValueFrom(this.http.get<ArrayResponse<CmsItem>>(url(CmsPath)));
  }
}
