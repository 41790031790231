import { Component, OnInit } from '@angular/core';
import {RoutingPaths} from "../RoutePaths";
import {AuthService} from "portal-lib";

@Component({
  selector: 'provider-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  routes = RoutingPaths;

  constructor(private auth: AuthService) { }

  ngOnInit() {
  }

}
