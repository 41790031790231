<nav class="navbar navbar-expand-md navbar-dark sticky-top">
  <div class="container-fluid">
    <button class="btn btn-link navbar-brand"
            [disabled]="!showUser()"
            [routerLink]="routes.Dashboard">
      <img src="/assets/ForfrontLogo_Clear.png"
           alt="Forefront Telecare Provider Portal"
           style="height: 40px;"/>
      <br/>
      Provider Portal
    </button>
    <div class="collapse navbar-collapse" *ngIf="showUser()">
      <ul class="navbar-nav me-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="routes.Dashboard">Dashboard</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="routes.MyFacilities">My Facilities</a>
        </li>
      </ul>
      <div class="align-middle" *ngIf="showUser()">
        <navbar-profile-menu></navbar-profile-menu>
      </div>
    </div>
  </div>
</nav>
