import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {PortalToasterService, SearchBase, SearchDataBase} from "portal-lib";
import {RestApiService} from "../../services/rest-api.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ProviderFacility} from "#psygnal-model/routes/my/provider/ProviderFacility";
import {MapTimezoneCodeToName} from "#psygnal-model/shared/TimezoneLookups";

@Component({
  selector: 'provider-my-facilities',
  templateUrl: './my-facilities.component.html',
  styleUrls: ['./my-facilities.component.scss']
})
export class MyFacilitiesComponent
  extends SearchBase<SearchDataBase>
  implements OnInit, AfterViewInit {

  facilities: ProviderFacility[];
  MapTimezoneCodeToName = MapTimezoneCodeToName;
  @ViewChild("searchControl") searchControl: ElementRef;

  constructor(
    private rest: RestApiService,
    private modalService: NgbModal,
    private toast: PortalToasterService) {
    super("name");
    this.searchFilter = this.searchFilter.bind(this);
    this.resetSearchData();
  }

  async ngOnInit() {
    this.resetSearchData();
    return this.loadPageData();
  }

  ngAfterViewInit(): void {
    this.afterViewInit(this.searchControl);
  }

  searchFilter(facility: ProviderFacility): boolean {
    let found = this.testSearch(facility.name);
    return found;
  }

  private async loadPageData() {
    try {
      this.loading = true;
      const response = await this.rest.getMyFacilities();
      this.facilities = response.result;
      this.loading = false;
    } catch (e) {
      this.toast.showError("Error loading my facilities. If this error persists, please contact tech services.");
      console.error(e);
      this.loading = false;
    }
  }
}
