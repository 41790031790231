export enum RoutePaths {
  Root = "",
  Unauthorized = "unauthorized",
  Login = "login",
  LoginContinue = "login_continue",
  Dashboard = "dashboard",
  MyFacilities = "facilities",
}

export namespace RoutingPaths {
  export const Dashboard = [`/${RoutePaths.Dashboard}`];
  export const Unauthorized = [`/${RoutePaths.Unauthorized}`];
  export const Login = [`/${RoutePaths.Login}`];
  export const MyFacilities = [`/${RoutePaths.MyFacilities}`];
}
