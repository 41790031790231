import {Component, OnInit} from '@angular/core';
import {RoutingPaths} from "../../routes/RoutePaths";
import {Router} from "@angular/router";
import {AuthService} from "portal-lib";

@Component({
  selector: 'provider-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  routes = RoutingPaths;

  constructor(private auth: AuthService,
    private router: Router) {
  }

  ngOnInit() {

  }

  showUser() {
    return this.auth.isAuthenticated();
  }
}
