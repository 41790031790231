import {environment} from "../environments/environment";
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PortalLibConfig, PortalLibModule, ProvidePortalLibConfig, ProvidePsygnalHttpInjector} from 'portal-lib';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {NgSelectModule} from "@ng-select/ng-select";
import {RoutePaths} from "./routes/RoutePaths";
import {MyFacilitiesComponent} from './routes/my-facilities/my-facilities.component';
import {DashboardComponent} from "./routes/dashboard/dashboard.component";
import {NavbarComponent} from "./controls/navbar/navbar.component";

@NgModule({
  declarations: [
    AppComponent,
    MyFacilitiesComponent,
    DashboardComponent,
    NavbarComponent,
  ],
  imports: [
    NgbModule,
    NgSelectModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    PortalLibModule,
  ],
  providers: [
    // add the http injector to add tokens to the API calls
    ProvidePsygnalHttpInjector(),
    // calling this function sets up configuration values for the shared library.
    // I much prefer this function call in a provider to the common forRoot() approach
    // many modules use
    ProvidePortalLibConfig(<PortalLibConfig> {
      portal: {
        name: environment.portal.name,
        loginRoute: RoutePaths.Login,
        unauthorizedRoute: RoutePaths.Unauthorized,
        profilePicMaxSize: environment.portal.profilePicMaxSize,
      },
      login: {
        allowPassword: false,
        allowO365: true,
        allowPcc: false,
      },
      auth: environment.auth,
      httpIntercept: environment.httpIntercept
    }),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
